import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import Spinner from '../Modals/Spinner';

function GoogleAuth({ setUser, showModal }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    try {
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      const refreshToken = query.get('refreshToken');
      const id = query.get('id');
      if (token && id) {
        // Delay to allow animation to be visible
        setTimeout(() => {
          setLoading(false);
          localStorage.setItem("id", id);
          localStorage.setItem("token", token);
          localStorage.setItem('refreshToken', refreshToken);
          makeAuthenticatedRequest(true, 'get',`/users/${id}`)
          .then(response => {
            setVerified(true);
            setMessage('Credentials verified! Redirecting...');
            setUser(response.data);
            navigate('/');
          })
          .catch(error => {
            console.error("Error fetching user:", error);
            setVerified(false);
            setMessage('Credentials verification failed! Redirecting...');
            localStorage.removeItem("token"); // Clear invalid token
            localStorage.removeItem("refreshToken"); // Clear invalid token
            localStorage.removeItem("id"); // Clear invalid id
            navigate('/');
          });
        }, 2000); // 2-second delay
      } else {
        setVerified(false);
        navigate('/'); // Redirect to login on error
      }
    } catch (error) {
      navigate("/login");
    }
  }, [location.search, navigate, setUser, showModal]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
    {loading ? (
      <>
        <Spinner message="Verifying your credentials ..."/>
      </>
    ) : verified ? (
      <p style={{ color: 'green' }}>{message}</p>
    ) : (
      <p style={{ color: 'red' }}>{message}</p>
    )}
    </div>
  );
}
export default GoogleAuth;
