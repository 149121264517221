import React from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

function TrendIndicator({ items }) {
    const monthlyTotals = {};
    
    // Sort items by date first
    const sortedItems = [...items].sort((a, b) => new Date(a.date) - new Date(b.date));
    
    // Use a 3-month moving average
    sortedItems.forEach(item => {
      const month = new Date(item.date).toLocaleString('default', { month: 'short', year: 'numeric' });
      monthlyTotals[month] = (monthlyTotals[month] || 0) + Number(item.debit || 0);
    });
    
    const totals = Object.values(monthlyTotals);
    const last3Months = totals.slice(-3);
    const currentAvg = last3Months.reduce((a, b) => a + b, 0) / 3;
    const previous3Months = totals.slice(-6, -3);
    const previousAvg = previous3Months.reduce((a, b) => a + b, 0) / 3;
    
    const percentChange = previousAvg ? ((currentAvg - previousAvg) / previousAvg * 100).toFixed(1) : 0;
    
    const color = percentChange > 0 ? '#ff4444' : '#44ff44';
    const arrow = percentChange > 0 ? <FaArrowUp /> : <FaArrowDown />;
  
    return (
      <div style={{ 
        position: 'absolute', 
        top: '10px', 
        right: '10px',
        color: color,
        fontWeight: 'bold'
      }}>
        {arrow} {Math.abs(percentChange)}%
      </div>
    );
  }

export default TrendIndicator;