import React from 'react';
import { FaPlus, FaPen, FaTrash, FaHistory, FaUpload  } from 'react-icons/fa';

function TreasuryDataTableActions({ 
	openAddEditTreasuryModal,
	openHistoryModal,
	openUploadModal,
	handleDeleteTreasury,
	editingTreasuryId,
	openUpdateBalanceModal}) {

	return(
		<div className="data-actions-container">
			<div className="data-actions-left">
				<button onClick={openAddEditTreasuryModal} title={editingTreasuryId ? "Edit Treasury" : "Add Treasury"}>
					{editingTreasuryId ? <FaPen/> : <FaPlus/>}
				</button>

				{editingTreasuryId && <button onClick={handleDeleteTreasury} title="Delete Treasury">
					<FaTrash />
				</button>}
				{editingTreasuryId && <button onClick={openHistoryModal} title="Check History">
					<FaHistory />
				</button>}
			</div>
			<div className="data-actions-right">
				{editingTreasuryId && <button onClick={openUpdateBalanceModal} title="Update Balance">
					<strong>+/-</strong>
				</button>}
				<button onClick={openUploadModal} title='Upload Data'>
					<FaUpload />
				</button>
			</div>
		</div>
	);
}
export default TreasuryDataTableActions;