import React, { useState } from 'react';
import '../styles/ExchangeRates.css';
import { FaSyncAlt } from 'react-icons/fa';

function ExchangeRates({ user, currencies, showModal }) {
  const [conversion, setConversion] = useState({
    fromCurrency: user.currency || '',
    toCurrency: user.currency || '',
    fromValue: 0,
    toValue: 0,
  });

  const convertCurrency = (value, fromCurrency, toCurrency) => {
    if (!value || !fromCurrency || !toCurrency) return 0;

    const fromCurrencyData = currencies.find((c) => c.abbreviation === fromCurrency);
    const toCurrencyData = currencies.find((c) => c.abbreviation === toCurrency);

    if (!fromCurrencyData || !toCurrencyData) return 0;

    const itemCurrencyRate = fromCurrencyData.rateToUSD;
    const selectedCurrencyRate = toCurrencyData.rateToUSD;
    return ((Number(value) / itemCurrencyRate) * selectedCurrencyRate).toFixed(2);
  };

  const handleChange = (updatedConversion) => {
    const newValue = convertCurrency(
      updatedConversion.fromValue,
      updatedConversion.fromCurrency,
      updatedConversion.toCurrency
    );

    setConversion({ ...updatedConversion, toValue: newValue });
  };

  const handleReverse = () => {
    setConversion({
      fromCurrency: conversion.toCurrency,
      toCurrency: conversion.fromCurrency,
      fromValue: conversion.fromValue,
      toValue: convertCurrency(
        conversion.fromValue,
        conversion.toCurrency,
        conversion.fromCurrency
      ),
    });
  };

  if (!currencies || currencies.length === 0) {
    showModal("Currencies are not available. Please try again later");
  }

  return (
    <div className="exchange-container">
      <h2>Exchange Rates</h2>
      <div className="exchange-from">
        <select
          value={conversion.fromCurrency}
          onChange={(e) =>
            handleChange({ ...conversion, fromCurrency: e.target.value })
          }
        >
          <option value="">Select Currency</option>
          {currencies.map((currency) => (
            <option key={currency.abbreviation} value={currency.abbreviation}>
              {currency.name} ({currency.abbreviation})
            </option>
          ))}
        </select>
        <input
          type="number"
          value={conversion.fromValue}
          onChange={(e) =>
            handleChange({ ...conversion, fromValue: e.target.value })
          }
        />
      </div>
      <button onClick={handleReverse}>
        <FaSyncAlt />
      </button>
      <div className="exchange-to">
        <select
          value={conversion.toCurrency}
          onChange={(e) =>
            handleChange({ ...conversion, toCurrency: e.target.value })
          }
        >
          <option value="">Select Currency</option>
          {currencies.map((currency) => (
            <option key={currency.abbreviation} value={currency.abbreviation}>
              {currency.name} ({currency.abbreviation})
            </option>
          ))}
        </select>
        <input type="number" value={conversion.toValue} readOnly />
      </div>
      {}
    </div>
  );
}

export default ExchangeRates;