import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Profile from './screens/Profile';
import Footer from './components/Footer';
import Login from './screens/Login';
import Register from './screens/Register';
import Dashboard from './screens/Dashboard';
import Data from './screens/Data';
import Treasury from './screens/Treasury';
import Spinner from './Modals/Spinner';
import Modal from './Modals/Modal';
import ExchangeRates from './screens/ExchangeRates';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import VerifyToken from './screens/VerifyToken';
import VerifyAccount from './screens/VerifyAccount';
import ChangePassword from './screens/ChangePassword';
import GoogleAuth from './screens/GoogleAuth';
import { makeAuthenticatedRequest } from './config/api';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Centralized Modal Functions
  const showModal = (message, error) => {
    const errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.response?.statusText ||
      error?.message ||
      '';
    setModalMessage(`${message} ${errorMessage}`);
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
    setModalMessage('');
  };

  // Fetch Data and User Information
  useEffect(() => {
    const fetchData = async () => {
      const id = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');

      try {
        if (id && token && refreshToken) {
          const userResponse = await makeAuthenticatedRequest(true, 'get', `/users/${id}`);
          setUser(userResponse.data);
        }
        const currencyResponse = await makeAuthenticatedRequest(false, 'get', `/currencies`);
        setCurrencies(currencyResponse.data || []);
      } catch (error) {
        showModal('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateProfilePicture = (newUrl) => {
    setUser((prevUser) => ({
      ...prevUser,
      profilePictureUrl: newUrl,
    }));
  };

  if (loading) {
    return <Spinner />;
  }

  // Centralized Protected Route Logic
  const renderProtectedRoute = (Component, extraProps = {}) => {
    if (!user) return <Login setUser={setUser} showModal={showModal} />;
    if (!user.verified) return <VerifyAccount user={user} showModal={showModal} />;
    return <Component user={user} showModal={showModal} currencies={currencies} {...extraProps} />;
  };

  return (
    <Router>
      <Modal isOpen={modalOpen} message={modalMessage} onClose={hideModal} duration={3000} />
      <Header user={user} setUser={setUser} />
      <div className="page">
        <Routes>
          <Route path="/" element={renderProtectedRoute(Dashboard)} />
          <Route path="/dashboard" element={renderProtectedRoute(Dashboard)} />
          <Route path="/data" element={renderProtectedRoute(Data)} />
          <Route path="/balance/*" element={renderProtectedRoute(Treasury)} />
          <Route
            path="/profile"
            element={renderProtectedRoute(Profile, { updateProfilePicture })}
          />
          <Route path="/exchange-rates" element={renderProtectedRoute(ExchangeRates)} />
          <Route path="/login" element={<Login setUser={setUser} showModal={showModal} />} />
          <Route
            path="/register"
            element={<Register showModal={showModal} currencies={currencies} />}
          />
          <Route path="/change-password" element={<ChangePassword user={user} showModal={showModal} />} />
          <Route path="/reset-password" element={<ResetPassword showModal={showModal} />} />
          <Route path="/forgot-password" element={<ForgotPassword showModal={showModal} />} />
          <Route path="/verify-token" element={<VerifyToken setUser={setUser} showModal={showModal} />} />
          <Route path="/google-auth" element={<GoogleAuth setUser={setUser} showModal={showModal} />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
