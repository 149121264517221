import React from 'react';
import { FaSortAmountDown, FaSortAmountDownAlt  } from 'react-icons/fa';

function TreasuryDataTable({ 
	currencies,
	currency,
	setCurrency,
	treasuries,
	editingTreasuryId,
	setEditingTreasuryId,
	fetchTreasuries,
	direction,
	setDirection,
	currentSortIndex,
	setCurrentSortIndex,
 }) { 
	const calculateTotal = (items, field) => {
		if (!field) {
			return '-';
		}
		const sum = items.reduce((acc, item) => {
			const value = Number(item[field]); // Convert item's field value to USD
			return acc + value;
		}, 0);
		return sum == 0 ? '-' : sum.toFixed(2);
	};

	const handleSort = async(index) => {
    setCurrentSortIndex(index);
    setDirection(!direction);
    await fetchTreasuries(!direction, index, currency);
  };

	const headerCell = (title, index) => {
    return <th>
      <label onClick={() => handleSort(index)}>{title}</label>
      {currentSortIndex !== index ? '' : !direction ? 
        <div className="sort-icon" onClick={() => handleSort(index)}>
          <FaSortAmountDown />
        </div> :                   
        <div className="sort-icon" onClick={() => handleSort(index)}>
          <FaSortAmountDownAlt />
        </div>}          
    </th>
  }; 

	return(
		<div>
			<div className="data-table-scroll">
				<table>
					<thead>
						<tr>
							{headerCell("Name", 0)}
							{headerCell("Currency", 1)}
							{headerCell("Type", 2)}
							{headerCell("Limit", 3)}
							{headerCell("Last Balance", 4)}
						</tr>
						<tr style={{color:'red', borderTop: '1px solid black', borderBottom: '1px solid black'}}>
							<th colSpan={3}>
							<select style={{width: '100%', height:'100%', borderRadius: '3px', padding: '10px'}}
								id="currency"
								value={currency}
								onChange={(e) => {
									setCurrency(e.target.value);
									localStorage.setItem("curr", e.target.value);
									setEditingTreasuryId('');
									fetchTreasuries(direction, currentSortIndex, e.target.value)
								}}
							>
								<option value={''}>Please Select Currency</option>
								{currencies.map((currency) => (
								<option key={currency.abbreviation} value={currency.abbreviation}>
									{currency.name} ({currency.abbreviation})
								</option>
								))}
							</select>
							</th>
							<th>
								{calculateTotal(treasuries, 'limit')}
							</th>
							<th>
								{calculateTotal(treasuries, 'lastBalance')}
							</th>
						</tr>
					</thead>
					<tbody>
						{treasuries.map((treasury) => (
							<tr
								key={treasury.id}
								onClick={() => editingTreasuryId === treasury.id ? setEditingTreasuryId('') :  setEditingTreasuryId(treasury.id)}
								className={
									editingTreasuryId && treasury.id === editingTreasuryId
										? "editable-row"
										: "regular-row"
								}
							>
								<td>{treasury.name}</td>
								<td>{treasury.currency}</td>
								<td>{treasury.type}</td>
								<td>{Number(treasury.limit).toFixed(2)}</td>
								<td>{Number(treasury.lastBalance).toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
export default TreasuryDataTable;