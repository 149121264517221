import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {treasuriesCache} from '../services/cache';

const AddEditTreasuryModal = ({
  user,
  currencies,
  Incurrency,
  onClose,
  treasuries,
  fetchTreasuries,
  editingTreasuryId}) => {
  const [limit, setLimit] = useState(0);
  const [name, setName] = useState('');
  const [type, setType] = useState('debit');
  const [currency, setCurrency] = useState(Incurrency);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editingTreasuryId) {
      const treasury = treasuries.find(t => t.id === editingTreasuryId);
      if (treasury) {
        setLimit(treasury.limit);
        setName(treasury.name);
        setType(treasury.type);
        setCurrency(treasury.currency || 'SAR');
      }
    }
  }, []);

  const handleAddTreasry = async () => {
    const exists = treasuries.some(t => t.name.toLowerCase() === name.toLowerCase().trim());
    if (!exists) {
      try {
        setLoading(true);
        await makeAuthenticatedRequest(true, 'post', '/treasuries', 
          { name, limit: type === 'debit' ? 0 : limit, currency, type, userId: user.id });
        treasuriesCache.clear();
        await fetchTreasuries();
        onClose();
      } catch (error) {
        setError("Failed to add treasury");
      } finally {
        setLoading(false);
      }
    } else{
      setError("Treasury with same name exists");
    }
  };
  const handleEditTreasury = async () => {
    if (name && name.trim() !== '') {
      const exists = treasuries.some(t => t.name.toLowerCase() === name.toLowerCase().trim() && t.id != editingTreasuryId);
      if (!exists) {
        try {
          setLoading(true);
          await makeAuthenticatedRequest(true, 'put', `/treasuries/${editingTreasuryId}`, 
            { name, type, limit: type === 'debit' ? 0 : limit, currency });
          treasuriesCache.clear();
          await fetchTreasuries();       
          onClose();
        } catch (error) {
          setError("Failed to update treasury");
        } finally {
          setLoading(false);
        }
      } else{
        setError("Treasury with same name exists");
      }
    }
  };
  const handleNegativeChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 || newValue === '') {
      setLimit(newValue);
    }
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>{editingTreasuryId ? "Edit Treasury" : "Add Treasury"}</h3>
        <p style={{color: 'red'}}>{error}</p>
        <div className="sidebar">
        <label htmlFor="name">Type</label>
          <select value={type} onChange={(e) => {setType(e.target.value); if(e.target.value === 'debit') setLimit(0)}}>
            <option value="debit">Debit</option>
            <option value="credit">Credit</option>
          </select>
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            value={name}
            placeholder="Name...."
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="currency">Currency</label>
          <select
            id="currency"
            value={currency}
            disabled={!editingTreasuryId}
            onChange={(e) => setCurrency(e.target.value)}
          >
            {currencies.map((currency) => (
              <option key={currency.abbreviation} value={currency.abbreviation}>
                {currency.name} ({currency.abbreviation})
              </option>
            ))}
          </select>
          {type !== 'debit' && 
          <>
            <label htmlFor="limit">Limit</label>
            <input
              id="limit"
              type="number"
              value={limit}
              placeholder="Enter Limit"
              onChange={(e) => handleNegativeChange(e)}
              disabled={type === 'debit'}
            />
          </>}
          <div className="modal-buttons">
            {!editingTreasuryId ? <button disabled={loading} className="confirm-btn" onClick={handleAddTreasry}>Add</button> :
              <button disabled={loading} className="confirm-btn" onClick={handleEditTreasury}>Update</button>}
            <button className="cancel-btn" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditTreasuryModal;