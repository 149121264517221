import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../styles/FilterSidebar.css';

const FilterSidebar = ({ 
  onApplyFilters, 
  onApplyCurrency,
  filterOptions, 
  allCategories, 
  allSubcategories, 
  itemType,
  currencies,
  currency, 
  filterCurrency,
  setFilterCurrency,
  setCurrency }) => {
  const [localFilters, setLocalFilters] = useState(filterOptions);
  const [filterMode, setFilterMode] = useState(filterCurrency ? 'filter' : 'convert'); // 'convert' or 'filter'
  const [isExpanded, setIsExpanded] = useState(window.innerWidth >= 425);
	const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(256); // default width

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const diff = startX - e.clientX;
    const newWidth = Math.max(200, Math.min(400, sidebarWidth + diff));
    setSidebarWidth(newWidth);
    setStartX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add to useEffect
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, startX, sidebarWidth]);

  useEffect(() => {
    setFilterMode(filterCurrency ? 'filter' : 'convert');
  }, [filterCurrency]);

  const handleCurrencyChange = (newCurrency, newMode) => {
    setCurrency(newCurrency);
    if (newMode === 'filter') {
      setFilterCurrency(true);
      onApplyCurrency(newCurrency, true);
    } else {
      setFilterCurrency(false);
      onApplyCurrency(newCurrency, false);
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };

  const handleChange = (field, value) => {
		const newFilters = {
			...localFilters,
			[field]: value,
		};
		setLocalFilters(newFilters);
		onApplyFilters(newFilters);
	};
	
	const handleCheckboxToggle = (field, value) => {
		const newFilters = {
			...localFilters,
			[field]: localFilters[field].includes(value)
				? localFilters[field].filter((item) => item !== value)
				: [...localFilters[field], value],
		};
		setLocalFilters(newFilters);
		onApplyFilters(newFilters);
	};
	
  return (
    <div 
      className={`sidebar-container ${isExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'}`}
      style={{ width: isExpanded ? `${sidebarWidth}px` : '0px' }}
    >
      <button 
        title={isExpanded ? 'Hide Filters' : 'Show Filters'}
        className="sidebar-toggle"
        onMouseDown={handleMouseDown}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <FaChevronLeft size={16}/> : <FaChevronRight size={16}/>}
      </button>
      <div className={`sidebar-content ${isExpanded ? 'content-visible' : 'content-hidden'}`}>
        <h3 className="sidebar-title">Filters</h3>
        <div className="filter-section">
          <h4>Currency Settings</h4>
          <div className="input-field">
            <label>Display Currency</label>
            <select
              value={currency}
              onChange={(e) => handleCurrencyChange(e.target.value, filterMode)}
            >
              <option value={''}>All Currencies</option>
              {currencies?.map((curr) => (
                <option key={curr.abbreviation} value={curr.abbreviation}>
                  {curr.name} ({curr.abbreviation})
                </option>
              ))}
            </select>
          </div>
          <div className="input-field">
            <label>Currency Mode</label>
            <select
              value={filterMode}
              onChange={(e) => {
                setFilterMode(e.target.value);
                handleCurrencyChange(currency, e.target.value);
              }}
            >
              <option value="convert">Convert All to Selected Currency</option>
              <option value="filter">Show Only Selected Currency</option>
            </select>
          </div>
        </div>
        {/* Categories Section */}
        <div className="filter-section">
          <h4>Categories</h4>
          <div className="checkbox-list">
            {allCategories.sort().map((category) => (
              <div key={category || 'N/A'} className="checkbox-item">
                <input
                  type="checkbox"
                  id={`category-${category || 'N/A'}`}
                  checked={localFilters.categories.includes(category)}
                  onChange={() => handleCheckboxToggle('categories', category)}
                />
                <label htmlFor={`category-${category || 'N/A'}`}>{category || 'N/A'}</label>
              </div>
            ))}
          </div>
        </div>
        {/* Categories Section */}
        <div className="filter-section">
          <h4>Subcategories</h4>
          <div className="checkbox-list">
            {[...new Set(allSubcategories
						.filter(sub => localFilters.categories.includes(sub.category) || localFilters.categories.length < 1)
						.map(sub => sub.name))].sort().map((subcategory) => (
              <div key={subcategory || 'N/A'} className="checkbox-item">
                <input
                  type="checkbox"
                  id={`subcategory-${subcategory || 'N/A'}`}
                  checked={localFilters.subcategories.includes(subcategory)}
                  onChange={() => handleCheckboxToggle('subcategories', subcategory)}
                />
                <label htmlFor={`subcategory-${subcategory || 'N/A'}`}>{subcategory || 'N/A'}</label>
              </div>
            ))}
          </div>
        </div>
        {/* Date Range Section */}
        <div className="filter-section">
          <h4>Date Range</h4>
          <div className="input-field">
            <label htmlFor="start-date">From</label>
            <input
              type="date"
              id="start-date"
							min={itemType == 'Actual' ? '' : getTomorrowDate()}
							max={itemType == 'Actual' ? localFilters.dateRange && localFilters.dateRange.end ? new Date(localFilters.dateRange.end).toISOString().split("T")[0] :  new Date().toISOString().split("T")[0] : ''}						
              value={localFilters.dateRange.start || ''}
              onChange={(e) =>
                handleChange('dateRange', { ...localFilters.dateRange, start: e.target.value })
              }
            />
          </div>
          <div className="input-field">
            <label htmlFor="end-date">To</label>
            <input
              type="date"
              id="end-date"
							min={itemType == 'Actual' ? localFilters.dateRange && localFilters.dateRange.start ? new Date(localFilters.dateRange.start).toISOString().split("T")[0] : '' : getTomorrowDate()}
							max={itemType == 'Actual' ? new Date().toISOString().split("T")[0] : ''}
              value={localFilters.dateRange.end || ''}
              onChange={(e) =>
                handleChange('dateRange', { ...localFilters.dateRange, end: e.target.value })
              }
            />
          </div>
        </div>
        {/* Ranges Section */}
        {[
          { title: 'Debit Amount', key: 'debitRange' },
          { title: 'Credit Amount', key: 'creditRange' },
        ].map(({ title, key }) => (
          <div key={key} className="filter-section">
            <h4>{title}</h4>
            <div className="input-group">
              <div className="input-field">
                <label htmlFor={`min-${key}`}>Min</label>
                <input
                  type="number"
                  id={`min-${key}`}
                  value={localFilters[key].min || ''}
                  onChange={(e) =>
                    handleChange(key, { ...localFilters[key], min: e.target.value })
                  }
                />
              </div>
              <div className="input-field">
                <label htmlFor={`max-${key}`}>Max</label>
                <input
                  type="number"
                  id={`max-${key}`}
                  value={localFilters[key].max || ''}
                  onChange={(e) =>
                    handleChange(key, { ...localFilters[key], max: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterSidebar;