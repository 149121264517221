import React, { useEffect } from 'react';
import '../styles/Floating.css';

function Modal({ isOpen, message, onClose, duration = 3000 }) {

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Automatically close after the specified duration
      }, duration);

      return () => clearTimeout(timer); // Cleanup timer when component unmounts or isOpen changes
    }
  }, [isOpen, duration, onClose]);
  
  if (!isOpen) return null;

  return (
    <div className="floating-alert">
      <p>{message}</p>
      <button className="close-btn" onClick={onClose}>
        &times;
      </button>
    </div>
  );
}

export default Modal;