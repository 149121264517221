import React, { useState } from 'react';

function FilterModal({ onClose, onApplyFilters, filterOptions, allCategories, allSubcategories, itemType }) {
  const [activeTab, setActiveTab] = useState('categories'); // Default tab
  const [localFilters, setLocalFilters] = useState(filterOptions);

   const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (field, value) => {
		const newFilters = {
			...localFilters,
			[field]: value,
		};
		setLocalFilters(newFilters);
	};
	
	const handleCheckboxToggle = (field, value) => {
		const newFilters = {
			...localFilters,
			[field]: localFilters[field].includes(value)
				? localFilters[field].filter((item) => item !== value)
				: [...localFilters[field], value],
		};
		setLocalFilters(newFilters);
	};

	const isObject = (value) => value && typeof value === 'object' && !Array.isArray(value);

	const clearFilter = (field) => {
		const currentFilter = localFilters[field];
		const newValue = Array.isArray(currentFilter)
			? []
			: isObject(currentFilter)
			? currentFilter.start !== undefined
				? { start: '', end: '' }
				: { min: '', max: '' }
			: '';
		const newFilters = {
			...localFilters,
			[field]: newValue,
		};
		setLocalFilters(newFilters);
	};
  const handleApply = () => {
    onApplyFilters(localFilters);
    if(onClose) onClose();
  };
  const handleSelectAll = () => {
    setLocalFilters({ 
			...localFilters,
      categories: activeTab === 'categories' ? allCategories : localFilters.categories,
      subcategories: activeTab === 'subcategories' ? allSubcategories.map(s => s.name) : localFilters.subcategories,
    });
  };
  
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  };
  return (
	<div className="modal-overlay" onClick={onClose}>
		<div className="modal-content" style={{maxWidth: 400}} onClick={(e) => e.stopPropagation()}>
		<h3>Filter Options</h3>
			<div className="filter-tabs">
			{[{title: 'Categories', key: 'categories'}, {title: 'Subcategories', key: 'subcategories'}, 
				{title: 'Dates Range', key: 'dateRange'}, {title: 'Depit Amount', key: 'debitRange'}, 
				{title: 'Credit Amount', key: 'creditRange'}].map((tab) => (
					<button
					key={tab.key}
					className={`filter-tab.key ${activeTab === tab.key ? 'active-tab' : ''}`}
					onClick={() => handleTabChange(tab.key)}
					>
					{tab.title}
					{localFilters[tab.key] &&
							((Array.isArray(localFilters[tab.key]) && localFilters[tab.key].length > 0) ||
							 (typeof localFilters[tab.key] === 'object' && 
									(localFilters[tab.key].start || localFilters[tab.key].end ||
									localFilters[tab.key].min || localFilters[tab.key].max)) ) && (
							<span
									className="clear-filter"
									title={`Clear ${tab.key} filter`}
									onClick={(e) => {
									e.stopPropagation(); // Prevent switching tab.keys
									clearFilter(tab.key);
									}}
							>
									✖
							</span>
							)}
					</button>
			))}
			</div>
			<div className="filter-content">
			{activeTab === 'categories' && (
					<div className="category-list">
					{allCategories.sort().map((category) => (
							<div key={category || 'N/A'} className="category-item-list">
							<input
									type="checkbox"
									id={`category-${category || 'N/A'}`}
									checked={localFilters.categories.includes(category)}
									onChange={() => handleCheckboxToggle('categories', category)}
							/>
							<label htmlFor={`category-${category || 'N/A'}`}>{category || 'N/A'}</label>
							</div>
					))}
					</div>
			)}
			{activeTab === 'subcategories' && (
					<div className="category-list">
						{[...new Set(allSubcategories
						.filter(sub => localFilters.categories.includes(sub.category) || localFilters.categories.length < 1)
						.map(sub => sub.name))].sort().map((subcategory) => (
							<div key={subcategory || 'N/A'} className="category-item-list">
							<input
									type="checkbox"
									id={`subcategory-${subcategory || 'N/A'}`}
									checked={localFilters.subcategories.includes(subcategory)}
									onChange={() => handleCheckboxToggle('subcategories', subcategory)}
							/>
							<label htmlFor={`subcategory-${subcategory || 'N/A'}`}>{subcategory || 'N/A'}</label>
							</div>
					))}
					</div>
			)}
			{activeTab === 'dateRange' && (
					<div className="category-list">
					<div className="category-item">
							<label>From</label>
							<input
							type="date"
							min={itemType == 'Actual' ? '' : getTomorrowDate()}
							max={itemType == 'Actual' ? localFilters.dateRange && localFilters.dateRange.end ? new Date(localFilters.dateRange.end).toISOString().split("T")[0] :  new Date().toISOString().split("T")[0] : ''}
							value={localFilters.dateRange.start || ''}
							onChange={(e) =>
									handleChange('dateRange', {
									...localFilters.dateRange,
									start: e.target.value,
									})
							}
							/>
					</div>
					<div className="category-item">
							<label>To</label>
							<input
							type="date"
							min={itemType == 'Actual' ? localFilters.dateRange && localFilters.dateRange.start ? new Date(localFilters.dateRange.start).toISOString().split("T")[0] : '' : getTomorrowDate()}
							max={itemType == 'Actual' ? new Date().toISOString().split("T")[0] : ''}
							value={localFilters.dateRange.end || ''}
							onChange={(e) =>
									handleChange('dateRange', {
									...localFilters.dateRange,
									end: e.target.value,
									})
							}
							/>
					</div>
					</div>
			)}
			{activeTab === 'debitRange' && (
					<div className="category-list">
					<div className="category-item">
							<label>Min</label>
							<input
							type="number"
							value={localFilters.debitRange.min || ''}
							onChange={(e) =>
									handleChange('debitRange', {
									...localFilters.debitRange,
									min: e.target.value,
									})
							}
							/>
					</div>
					<div className="category-item">
							<label>Max</label>
							<input
							type="number"
							value={localFilters.debitRange.max || ''}
							onChange={(e) =>
									handleChange('debitRange', {
									...localFilters.debitRange,
									max: e.target.value,
									})
							}
							/>
					</div>
					</div>
			)}
			{activeTab === 'creditRange' && (
					<div className="category-list">
					<div className="category-item">
							<label>Min</label>
							<input
							type="number"
							value={localFilters.creditRange.min || ''}
							onChange={(e) =>
									handleChange('creditRange', {
									...localFilters.creditRange,
									min: e.target.value,
									})
							}
							/>
					</div>
					<div className="category-item">
							<label>Max</label>
							<input
							type="number"
							value={localFilters.creditRange.max || ''}
							onChange={(e) =>
									handleChange('creditRange', {
									...localFilters.creditRange,
									max: e.target.value,
									})
							}
							/>
					</div>
					</div>
			)}
			</div>
			<div className="modal-buttons">
					{activeTab == 'categories' || activeTab == 'subcategories' ? <button className="clear-btn" onClick={handleSelectAll}>
							Select All
					</button> : ''}
					<button className="cancel-btn" onClick={onClose}>
							Close
					</button>
					<button className="confirm-btn" onClick={handleApply}>
							Apply
					</button>
			</div>
		</div>    
  </div>
  );
}

export default FilterModal;