import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {balancesCache, treasuriesCache, allTreasuriesCache} from '../services/cache';

const AddBalanceModal = ({
  onClose,
  treasury,
  fetchTreasuries}) => {
  const [lastBalance, setLastBalance] = useState(0);
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleUpdateBalance = async () => {
    try {
      setLoading(true);
      const currentBalance = (Number(treasury.lastBalance) || 0) + Number(lastBalance);
      await makeAuthenticatedRequest(true, 'put', `/treasuries/balance/${treasury.id}`, { lastBalance: currentBalance, date });
      balancesCache.clear();
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchTreasuries();       
      onClose();
    } catch (error) {
      setError("Failed to update balance");
    } finally {
      setLoading(false);
    }
  }

  if(!treasury) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Add Balance</h3>
        <p style={{color: 'red'}}>{error}</p>
        <div className="sidebar">
          <label htmlFor="balance">Add to / Remove from Balance</label>
          <input
            id="balance"
            type="number"
            value={lastBalance}
            placeholder="Enter Limit"
            onChange={(e) => setLastBalance(e.target.value)}
          />
          <label htmlFor="date">Date</label>
          <input
            id="date"
            type="date"
            value={date}
            placeholder="Enter Limit"
            onChange={(e) => setDate(e.target.value)}
          />
          <div className="modal-buttons">
            <button className="confirm-btn" disabled={loading} onClick={handleUpdateBalance}>Add Balance</button>
            <button className="cancel-btn" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBalanceModal;