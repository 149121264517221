import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import Spinner from '../Modals/Spinner';
import '../styles/Login.css';

function ForgotPassword({ showModal }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setLoading(true);

    makeAuthenticatedRequest(false, 'post', '/users/request-password-reset', { email })
      .then(() => {
        setTimeout(() => {
          showModal("Reset password email sent successfully")
          navigate('/');
        }, 2000);
      })
      .catch(error => {
        showModal(error.response && error.response.status == 404 ? "User Not Found" : "Failed to send reset link");
        setLoading(false); // Stop loading if there’s an error
      });
  };

  return (
    <div className="login-container">
      <h2>Forgot Password</h2>
      <form onSubmit={handleForgotPassword}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          Send Reset Link
        </button>
        {loading && <Spinner message="Sending password reset link email..." />}
      </form>
    </div>
  );
}

export default ForgotPassword;