import React, { useState, useEffect } from 'react';
import {FaTimes, FaSearch  } from 'react-icons/fa';
import '../styles/SearchBar.css';

const SearchBar = ({ onSearch, searchTerm, setSearchTerm }) => {
  const [isTyping, setIsTyping] = useState(false);

  // Debounce search to avoid too many updates
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      onSearch(searchTerm);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsTyping(e.target.value !== '');
  };

  const clearSearch = () => {
    setSearchTerm('');
    setIsTyping(false);
    onSearch('');
  };

  return (
    <div className="search-container">
      <div className="search-input-wrapper">
        <FaSearch className="search-icon" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search..."
          className="search-input"
        />
        {isTyping && (
          <button onClick={clearSearch} className="clear-search">
            <FaTimes />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBar;