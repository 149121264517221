import React from 'react';
import { FaStepBackward } from 'react-icons/fa';

const DirectionToggle = ({ type, setType, currentLevel, handleGoBack }) => {
	return (
		<div className="toggle-switch-container">
		<span className="toggle-label">{type}</span>
			<label className="toggle-switch">
				<input
					type="checkbox"
					checked={type === 'Debit'}
					onChange={async(e) => {
						setType(e.target.checked ? 'Debit' : 'Credit');
					}}
				/>
				<span className="toggle-slider"></span>
			</label>
			{currentLevel === 'subcategories' && (
        <button onClick={handleGoBack}>
          <FaStepBackward /> Go Back
        </button>
      )}
	</div>
	);
};
export default DirectionToggle;