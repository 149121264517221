function DeleteModal({onClose, modalTitle, deleteAction}) {
  return (
    <div className="modal-overlay">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Confirm {modalTitle} Deletion</h3>
        <div className="modal-buttons">
        <button 
            className="confirm-delete-btn" 
            onClick={async () => {
            if (deleteAction) {await deleteAction(); onClose();}// Execute the stored action
            }}>Confirm</button>
            <button onClick={onClose} className="cancel-btn">Cancel</button>
        </div>
        </div>
    </div>
  );
}

export default DeleteModal;