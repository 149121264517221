import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import profilePicture from '../images/default-profile.png';
import logo from '../images/logo.png';
import '../styles/Header.css';
import NotificationBell from './NotificationBell';

function Header({ user, setUser }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (user) {
      fetchSignedUrl();
    }
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [user, profilePictureUrl]);

  const fetchSignedUrl = async () => {
    const getFileNameFromUrl = (url) => {
      const urlObj = new URL(url);
      const fileName = urlObj?.pathname.split('/').pop(); // Split by '/' and get the last part
      return fileName;
    };
    const isValidImageFile = (fileName) => {
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
      const fileExtension = fileName ? fileName.slice(fileName.lastIndexOf('.')).toLowerCase() : '';
      return allowedExtensions.includes(fileExtension);
    };
    if (user.profilePictureUrl) {
      const fileName = getFileNameFromUrl(user.profilePictureUrl);
      if (fileName && isValidImageFile(fileName)) {
        const response = await makeAuthenticatedRequest(
          true,
          'get',
          `/users/${user.id}/profile-picture-url?fileName=${fileName}`
        );
        setProfilePictureUrl(response.data.signedUrl);
      } else {
        setProfilePictureUrl(user.profilePictureUrl);
      }
    } else {
      setProfilePictureUrl(profilePictureUrl); // Fallback to default if fetching fails
    }
  };

  const handleLogout = () => {
    setDropdownOpen(false);
    navigate('/'); // Redirect to the login page
    setUser(null);
    localStorage.clear();
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const menuItems = [
    { path: '/profile', homePage: false, label: 'Profile' },
    { path: '/dashboard', homePage: true, label: 'Dashboard' },
    { path: '/data', homePage: false, label: 'Data' },
    { path: '/balance', homePage: false, label: 'Balance' },
    { path: '/exchange-rates', homePage: false, label: 'Exchange' },    
  ];

  return (
    <header className="header">
      <span onClick={() => navigate('/')} aria-label="Navigate to Home">
        <img
          src={logo}
          alt="My Finance App"
          className="logo"
          onError={(e) => (e.target.src = profilePicture)} // Fallback on error
        />
      </span>
      <nav>
        {user ? (
          <div className="profile-section" ref={dropdownRef}>
            <NotificationBell user={user} />
            <div
              className="profile-icon"
              onClick={toggleDropdown}
              tabIndex={0}
              role="button"
              aria-label="Toggle profile dropdown"
            >
              <img
                src={profilePictureUrl || profilePicture}
                alt="Profile"
                className="profile-picture-top"
                id="profile-picture-2"
              />
              <span className="dropdown-arrow">&#9660;</span>
            </div>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {menuItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    onClick={() => setDropdownOpen(false)}
                    className={
                      location.pathname === item.path || 
                      (item.homePage && location.pathname === '/')
                        ? 'active'
                        : ''
                    }
                  >
                    {item.label}
                  </Link>
                ))}
                <button onClick={handleLogout} aria-label="Logout">
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : ''}
      </nav>
    </header>
  );
}

export default Header;