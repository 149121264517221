// cache.js
export class LRUCache {
	constructor(maxSize = 100) {
		this.cache = new Map();
		this.maxSize = maxSize;
	}

	getKey(...args) {
		const params = {};
		args.forEach((arg, index) => {
			if (arg !== undefined && arg !== null) {
				params[`param${index}`] = arg;
			}
		});
		return JSON.stringify(params);
	}

	get(key) {
		const item = this.cache.get(key);
		if (item) {
			this.cache.delete(key);
			this.cache.set(key, item);
		}
		return item;
	}

	set(key, value) {
		if (this.cache.size >= this.maxSize) {
			const firstKey = this.cache.keys().next().value;
			this.cache.delete(firstKey);
		}
		this.cache.set(key, value);
	}

	clear() {
		this.cache.clear();
	}
}
export const itemsCache = new LRUCache(2000);
export const categoriesCache = new LRUCache(100);
export const subcategoriesCache = new LRUCache(200);
export const allCategoriesCache = new LRUCache(100);
export const allSubcategoriesCache = new LRUCache(200);
export const treasuriesCache = new LRUCache(100);
export const balancesCache = new LRUCache(2000);
export const allTreasuriesCache = new LRUCache(500);
