import React, { useState } from 'react';
import { FaPencilAlt, FaPlus, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import '../styles/CustomSelectInput.css';

const CustomSelectInput = ({
  label,
  value,
  options,
  handleAdd,
  handleEdit,
  handleDelete,
  handleChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const startAdd = () => {
    setIsEditing(true);
    setInputValue('');
  };

  const startEdit = () => {
    setIsEditing(true);
    setInputValue(value ? options.find((option) => option.id == value).name : '');
  };

  const confirmEditOrAdd = () => {
    if (inputValue.trim()) {
      if (value) {
        handleEdit(inputValue);
      } else {
        handleAdd(inputValue);
      }
    }
    setIsEditing(false);
    setInputValue('');
  };

  const cancelEditOrAdd = () => {
    setIsEditing(false);
    setInputValue('');
  };
  return (
    <div className="custom-select-component">
      <div className="select-container">
        {isEditing ? (
          <>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => handleInputChange(e)}
              placeholder="Enter name..."
              className="select-input"
            />
            <div className="icon-group-select">
              <button onClick={confirmEditOrAdd} title="Confirm" className="icon-button">
                <FaCheck className="icon-select" />
              </button>
              <button onClick={cancelEditOrAdd} title="Cancel" className="icon-button">
                <FaTimes className="icon-select" />
              </button>
            </div>
          </>
        ) : (
          <>
            <select
              className="select-input"
              value={value || ''}
              onChange={async (e) => {
                const selectedValue = e.target.value;
                handleChange(selectedValue);
              }}
            >
              <option value="">{`Select ${label}`}</option>
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <div className="icon-group">
              {value || value !== '' ? (
                <>
                  <button onClick={startAdd} title="Add New" className="icon-button">
                    <FaPlus className="icon-select" />
                  </button>
                  <button onClick={startEdit} title="Edit" className="icon-button">
                    <FaPencilAlt className="icon-select" />
                  </button>
                  <button onClick={() => handleDelete(value)} title="Delete" className="icon-button delete">
                    <FaTrash className="icon-select" />
                  </button>
                </>
              ) : (
                <button onClick={startAdd} title="Add New" className="icon-button">
                  <FaPlus className="icon-select" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CustomSelectInput;