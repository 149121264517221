import React, { useState } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css'
import Spinner from '../Modals/Spinner';

function ResetPassword({ showModal }) {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    makeAuthenticatedRequest(false, 'post', '/users/reset-password', { token, id, newPassword })
      .then(response => {
        localStorage.clear(); // Clear invalid tokens
        setTimeout(() => navigate('/'), 2000);
      })
      .catch(error => {
        showModal("Couldn't reset password " ,error);
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPassword} style={{ marginTop: '20px' }}>
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          required
        />  
        <button type="submit" disabled={loading}>
          Reset Password
        </button> 
        {loading && <Spinner message="Reseting password..." />}
      </form>
    </div>
  );
}

export default ResetPassword;