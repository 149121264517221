import React from 'react';
import '../styles/Spinner.css';
import '../styles/Modal.css';

function Spinner({ message = "Loading, please wait..." }) {
  return (
    <div className="modal-overlay">
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="spinner-container">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
          <p className="spinner-message">{message}</p>
        </div>
      </div>
    </div>
  );
}

export default Spinner;