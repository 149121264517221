import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import { FaBell } from 'react-icons/fa';
import '../styles/NotificationBell.css';

const NotificationBell = ({ user }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch notifications from the server
  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await makeAuthenticatedRequest(true, 'get', `/notifications/${user.id}`);
      const newNotifications = response.data;

      // Check for new notifications
      const hasNewNotifications =
        notifications.length > 0 &&
        (newNotifications.length > notifications.length ||
          newNotifications[0]?.id !== notifications[0]?.id);

      if (hasNewNotifications) {
        showBrowserNotification(newNotifications[0]);
      }

      setNotifications(newNotifications);
      setUnreadCount(newNotifications.filter((n) => !n.isRead).length);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Show browser notification
  const showBrowserNotification = (notification) => {
    if (!notification || !('Notification' in window)) return;

    if (Notification.permission === 'granted') {
      new Notification(notification.title, {
        body: notification.message,
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(notification.title, {
            body: notification.message,
          });
        }
      });
    }
  };
  
  // Mark a notification as read
  const markAsRead = async (notificationId) => {
    try {
      await makeAuthenticatedRequest(true, 'put', `/notifications/${notificationId}/read`);
      await fetchNotifications();
      setShowDropdown(false);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission();
    }
    fetchNotifications();
    const pollInterval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(pollInterval);
  }, [user.id]);

  useEffect(() => {
    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest('.notification-bell-container')) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="notification-bell-container">
      <div
        className="notification-icon"
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
      >
        <FaBell size={20} />
        {unreadCount > 0 && <span className="notification-count">{unreadCount}</span>}
      </div>

      {showDropdown && (
        <div className="notification-dropdown" onClick={(e) => e.stopPropagation()}>
          {loading ? (
            <div className="loading-spinner">Loading...</div>
          ) : notifications.length === 0 ? (
            <div className="no-notifications">No notifications</div>
          ) : (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className={`notification-item ${!notification.isRead ? 'unread' : ''}`}
                onClick={() => markAsRead(notification.id)}
              >
                <div className="notification-title">{notification.title}</div>
                <div className="notification-message">{notification.message}</div>
                <div className="notification-time">
                  {new Date(notification.createdAt).toLocaleString()}
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationBell;