import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import DirectionToggle from './DirectionToggle';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ items }) {
  const [currentLevel, setCurrentLevel] = useState('categories');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [type, setType] = useState('Debit');

  if (!items || items.length === 0) {
    return <p>No data to analyze. Adjust your filters and try again.</p>;
  }

  const colors = [
    "rgba(75, 192, 192, 0.8)", "rgba(255, 99, 132, 0.8)", "rgba(54, 162, 235, 0.8)", 
    "rgba(255, 206, 86, 0.8)", "rgba(153, 102, 255, 0.8)", "rgba(255, 159, 64, 0.8)",
    "rgba(201, 203, 207, 0.8)", "rgba(255, 99, 71, 0.8)", "rgba(255, 127, 80, 0.8)",
    "rgba(60, 179, 113, 0.8)", "rgba(30, 144, 255, 0.8)", "rgba(138, 43, 226, 0.8)",
    "rgba(0, 128, 128, 0.8)", "rgba(255, 105, 180, 0.8)", "rgba(173, 216, 230, 0.8)", 
    "rgba(240, 230, 140, 0.8)", "rgba(127, 255, 0, 0.8)", "rgba(220, 20, 60, 0.8)", 
    "rgba(148, 0, 211, 0.8)", "rgba(75, 0, 130, 0.8)", "rgba(250, 128, 114, 0.8)", 
    "rgba(244, 164, 96, 0.8)", "rgba(32, 178, 170, 0.8)", "rgba(100, 149, 237, 0.8)",
    ...Array.from({ length: 200 }, () => 
      `rgba(${Math.floor(Math.random() * 256)}, 
             ${Math.floor(Math.random() * 256)}, 
             ${Math.floor(Math.random() * 256)}, 
             0.8)`
    )
  ];

  // Aggregate data based on net balance (credit - debit)
  const aggregatedData = items
  .filter(i => type === 'Debit' ? Number(i.debit) !== 0 : Number(i.credit) !== 0)
  .reduce((acc, item) => {
    const key =
      currentLevel === 'categories'
        ? item.category || 'N/A'
        : item.subcategory || 'N/A';

    if (currentLevel === 'subcategories' && item.category !== selectedCategory) {
      return acc; // Skip unrelated subcategories
    }

    const netBalance = Number(item.credit) - Number(item.debit);
    acc[key] = (acc[key] || 0) + netBalance;
    return acc;
  }, {});

  const sortedaggregation = {};
  Object.entries(aggregatedData)
    .map(([key, value]) => ({ key, value: Math.abs(value) }))
    .sort((a, b) => b.value - a.value).forEach(x => {
      sortedaggregation[x.key] = x.value;
    }) // Sort by absolute value, highest first

  const labels = Object.keys(sortedaggregation);
  const rawValues = Object.values(sortedaggregation); // Actual net balances
  const values = rawValues.map((val) => Math.round(Math.abs(val), 2)); // Absolute values for visualization
  const total = values.reduce((a, b) => a + b, 0);
  const backgroundColors = colors.slice(0, labels.length); // Generate dynamic colors based on label count

  // Handle chart click to drill down
  const handleCategoryClick = (event, elements) => {
    if (elements.length > 0 && currentLevel === 'categories') {
      const categoryIndex = elements[0].index;
      const category = labels[categoryIndex];
      setSelectedCategory(category);
      setCurrentLevel('subcategories');
    }
  };

  // Go back to the category level
  const handleGoBack = () => {
    setCurrentLevel('categories');
    setSelectedCategory(null);
  };

  const handleType = (newType) => {
    setType(newType);
    
  };

  return (
    <div className="chart">
      <h3>
        {currentLevel === 'categories'
          ? 'Category Distribution'
          : `Subcategories of ${selectedCategory}`}
      </h3>
      <DirectionToggle type={type} setType={handleType} currentLevel={currentLevel} handleGoBack={handleGoBack} />
      <Pie
        data={{
          labels,
          datasets: [
            {
              data: values,
              backgroundColor: backgroundColors,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const sum = tooltipItem.raw;
                  const percentage = ((sum / total) * 100).toFixed(2);
                  return `${tooltipItem.label}: ${sum} (${percentage}%)`;
                },
              },
            },
          },
          onClick: handleCategoryClick,
        }}
      />
    </div>
  );
}

export default PieChart;