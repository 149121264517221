import React, { useState } from 'react';
import '../styles/Dashboard.css';

function NextMonthModal({ onClose, items, spendingGoal }) {
  const analyzeSpendingPatterns = (items) => {
    const patterns = new Map();
    items.filter(item => Number(item.debit != 0)).forEach((item) => {
      if (!item.date) return;
      const category = item.category || 'N/A';
      const month = new Date(item.date).getMonth();
      const debit = Number(item.debit) || 0;
      const credit = Number(item.credit) || 0;
      if (!patterns.has(category)) {
        patterns.set(category, {
          category,
          frequency: 0, // How often it appears
          totalAmount: 0,
          monthlyOccurrences: new Set(),
          isRecurring: false,
          variability: 0, // How much the amount varies
          amounts: [] // Track all amounts for variance calculation
        });
      }
      const pattern = patterns.get(category);
      pattern.frequency++;
      pattern.totalAmount += debit - credit;
      pattern.monthlyOccurrences.add(month);
      pattern.amounts.push(debit - credit);
    });
    patterns.forEach(pattern => {
      const mean = pattern.amounts.reduce((a, b) => a + b, 0) / pattern.amounts.length;
      pattern.variability = Math.sqrt(
        pattern.amounts.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / pattern.amounts.length
      );
      pattern.isRecurring = pattern.monthlyOccurrences.size >= 3;
      pattern.monthlyAverage = pattern.totalAmount / pattern.monthlyOccurrences.size;
    });
    return Array.from(patterns.values());
  };
  const generateSmartSuggestions = (patterns, goal) => {
    const totalMonthlySpend = patterns.reduce((sum, p) => sum + p.monthlyAverage, 0);
    const reductionNeeded = totalMonthlySpend - goal;
    if (reductionNeeded <= 0) {
      return patterns.map(p => ({
        ...p,
        suggested: p.monthlyAverage,
        priority: 'Within Budget',
        suggestion: 'Current spending is under budget'
      }));
    }
    return patterns.map(p => {
      let priority;
      let suggestedReduction = 0;
      let suggestion;
      if (p.isRecurring && p.variability < p.monthlyAverage * 0.2) {
        priority = 'Fixed Cost';
        suggestedReduction = 0;
        suggestion = 'Essential recurring expense - consider long-term alternatives';
      } else if (p.monthlyAverage > totalMonthlySpend * 0.2) {
        priority = 'High Impact';
        suggestedReduction = reductionNeeded * 0.4 * (p.monthlyAverage / totalMonthlySpend);
        suggestion = 'Major expense category - focus on reducing this';
      } else if (p.variability > p.monthlyAverage) {
        priority = 'Variable';
        suggestedReduction = reductionNeeded * 0.4 * (p.monthlyAverage / totalMonthlySpend);
        suggestion = 'Highly variable spending - look for consistency';
      } else {
        priority = 'Flexible';
        suggestedReduction = reductionNeeded * 0.2 * (p.monthlyAverage / totalMonthlySpend);
        suggestion = 'Consider reducing or finding alternatives';
      }
      return {
        ...p,
        priority,
        suggested: Math.max(0, p.monthlyAverage - suggestedReduction),
        suggestion
      };
    }).sort((a, b) => b.monthlyAverage - a.monthlyAverage);
  };
  const patterns = analyzeSpendingPatterns(items);
  const suggestions = generateSmartSuggestions(patterns, spendingGoal);
  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Fixed Cost': return '#4CAF50';
      case 'High Impact': return '#f44336';
      case 'Variable': return '#ff9800';
      case 'Flexible': return '#2196f3';
      default: return '#757575';
    }
  };
  const [adjustments, setAdjustments] = useState(
    suggestions.reduce((acc, s) => ({ ...acc, [s.category]: s.suggested }), {})
  );

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Smart Spending Analysis</h3>
        <p>Monthly Savings Goal: {(100-Number(spendingGoal)).toFixed(2)}%</p>
        <div className="suggestions-list" style={{maxHeight: 300, overflowY: 'auto'}}>
          {suggestions.map((s) => (
            <div key={s.category} className="suggestion-item" style={{marginBottom: '20px'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                <span style={{fontWeight: 'bold'}}>{s.category}</span>
                <span style={{
                  color: getPriorityColor(s.priority),
                  fontWeight: 'bold'
                }}>{s.priority}</span>
              </div>
              <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <span style={{minWidth: '100px', textAlign: 'right'}}>
                  {adjustments[s.category].toFixed(2)}
                </span>
              </div>
              <div style={{fontSize: '0.9em', color: '#666', marginTop: '5px'}}>
                {s.suggestion}
              </div>
            </div>
          ))}
        </div>
        <div className="modal-buttons">
          <button className="cancel-btn" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default NextMonthModal;