import React, {useState} from 'react';
import { Bar } from 'react-chartjs-2';
import NextMonthModal from '../Modals/NextMonthModal'
import TrendIndicator from './TrendIndicator';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { FaLightbulb } from 'react-icons/fa';
ChartJS.register(annotationPlugin, ArcElement, Tooltip, Legend,);

function PredictionChart({ items }) {
  const [nextMonthModalOpen, setNextMonthModalOpen] = useState(false);
  const [spendingGoal, setSpendingGoal] = useState(80);

  if (!items || items.length === 0) {
    return <p>No data to analyze. Adjust your filters and try again.</p>;
  }
  
  const calculateMonthlyTotals = (items) => {
    const monthlyTotals = {};
    items.forEach(item => {
      const date = new Date(item.date);
      if (isNaN(date.getTime())) return; // Skip invalid dates
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`; // Format: YYYY-MM
      monthlyTotals[monthKey] = (monthlyTotals[monthKey] || 0) + Number(item.debit || 0);
    });
    return monthlyTotals;
  };

  const calculateMonthlyIncome = (items) => {
    const monthlyIncome = {};
    items.forEach(item => {
      const date = new Date(item.date);
      if (isNaN(date.getTime())) return;
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
      monthlyIncome[monthKey] = (monthlyIncome[monthKey] || 0) + Number(item.credit || 0);
    });
    return monthlyIncome;
  };

  const calculateSeasonalPattern = (monthlyData) => {
    const monthlyAverages = {};
    Object.entries(monthlyData).forEach(([monthKey, amount]) => {
      const month = parseInt(monthKey.split('-')[1], 10) - 1;
      if (!monthlyAverages[month]) {
        monthlyAverages[month] = { total: 0, count: 0 };
      }
      monthlyAverages[month].total += amount;
      monthlyAverages[month].count += 1;
    });

    const seasonalFactors = {};
    Object.entries(monthlyAverages).forEach(([month, data]) => {
      seasonalFactors[month] = data.total / data.count;
    });

    return seasonalFactors;
  };
  
  const calculateNextMonthPrediction = (monthlyData, seasonalFactors) => {
    const currentMonth = new Date();
    const nextMonth = (currentMonth.getMonth() + 1) % 12;
    const seasonalFactor = seasonalFactors[nextMonth] || 1;

    const recentMonths = Object.values(monthlyData).slice(-3);
    const baseAverage = recentMonths.length
      ? recentMonths.reduce((a, b) => a + b, 0) / recentMonths.length
      : 0;

    return baseAverage * seasonalFactor;
  };

  const calculateSavingsGrowth = (monthlyData, monthlyIncome) => {
    let runningTotal = 0;
    const growthData = {};
    
    Object.keys(monthlyData).forEach(month => {
      const expenses = monthlyData[month] || 0;
      const income = monthlyIncome[month] || 0;
      runningTotal += (income - expenses);
      growthData[month] = runningTotal;
    });
  
    return growthData;
  };

  const monthlyData = calculateMonthlyTotals(items);
  const seasonalFactors = calculateSeasonalPattern(monthlyData);
  const prediction = calculateNextMonthPrediction(monthlyData, seasonalFactors);
  const monthlyIncome = calculateMonthlyIncome(items);
  const incomeSeasonalFactors = calculateSeasonalPattern(monthlyIncome);
  const incomePrediction = calculateNextMonthPrediction(monthlyIncome, incomeSeasonalFactors);

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const nextMonthLabel = nextMonth.toLocaleString('default', { month: 'short', year: 'numeric' });
  const pastMonthsAverage = Object.values(monthlyData)
  .reduce((sum, value) => sum + value, 0) / Object.values(monthlyData).length;
  const pastMonthsIncomeAverage = Object.values(monthlyIncome)
  .reduce((sum, value) => sum + value, 0) / Object.values(monthlyIncome).length;
  const savingsGrowth = calculateSavingsGrowth(monthlyData, monthlyIncome);
  
  const data = {
    labels: [...Object.keys(monthlyData), nextMonthLabel],
    datasets: [
      {
        label: 'Danger Zone',
        data: [],
        borderColor: 'rgba(255, 99, 132, 0.3)',
        borderWidth: 2,
      },
      {
        label: 'Monthly Spending',
        data: [...Object.values(monthlyData), prediction],
        backgroundColor: 'rgba(255, 99, 132, 0.6)'
      },
      {
        label: 'Monthly Income',
        data: [...Object.values(monthlyIncome), incomePrediction],
        backgroundColor: 'rgba(75, 192, 192, 0.6)'
      },
      {
        label: 'Net Savings',
        data: [...Object.values(savingsGrowth), incomePrediction - prediction],
        type: 'line',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: false
      },
      {
        label: 'Savings Goal',
        data: Array(Object.keys(monthlyData).length+1).fill(pastMonthsIncomeAverage * (spendingGoal/100)), // 20% of average monthly expenses
        type: 'line',
        borderColor: 'rgba(255, 215, 0, 1)', // Gold color
        borderWidth: 2,
        borderDash: [5, 5], // Dashed line
      }
    ]
};

  return (
    <div className="chart">
      <h3>Spending Prediction</h3>
      <input type="number" value={spendingGoal} onChange={(e) => setSpendingGoal(e.target.value)}
        min="0" max="100" title='Spending Goal'
        style={{
          position: 'absolute',
          top: '5px',
          right: '0',
          zIndex: 1,
          border: '1px solid #ddd',
          padding: '4px',
          borderRadius: '4px',
          width: '50px',
          marginRight: '125px'
        }}/>
      <button
        onClick={() => setNextMonthModalOpen(true)}
        title="Next Month Suggestions"
        style={{
          position: 'absolute',
          top: '5px',
          right: '80px',
          height: '25px',
          zIndex: 1,
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          padding: '8px 16px',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        <FaLightbulb />
      </button>
      <TrendIndicator items={items} />
      <Bar data={data} options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                switch(context.dataset.label) {
                  case 'Monthly Spending':
                    return `Expenses: ${context.raw.toFixed(2)}`;
                  case 'Monthly Income': 
                    return `Income: ${context.raw.toFixed(2)}`;
                  case 'Net Savings':
                    return `Savings: ${context.raw.toFixed(2)}`;
                  case 'Savings Goal':
                    return `Target savings: ${context.raw.toFixed(2)} (20% of avg income)`;
                  default:
                    return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
                }
              }
            }
          },
          annotation: {
            annotations: {
              warning: {
                type: 'box',
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                borderColor: 'red',
                borderWidth: 1,
                xMin: Object.keys(monthlyData)[0],
                xMax: nextMonthLabel,
                yMin: pastMonthsAverage,
                yMax: Object.keys(monthlyData)[Object.keys(monthlyData).length - 1],
                display: true
              }
            }
          }
        }
      }} />
      {nextMonthModalOpen && (
        <NextMonthModal 
          onClose={() => setNextMonthModalOpen(false)}
          items={items} spendingGoal={spendingGoal}
        />
      )}
    </div>
  );
}

export default PredictionChart;