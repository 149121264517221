import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ChartNavigator({ currentChart, onChangeChart }) {
  const charts = [
    "Spending/Income Analysis",
    "Balance History",
    "Future Forecast"
  ];
  
  const currentIndex = charts.indexOf(currentChart);
  const prevChart = charts[currentIndex - 1] || charts[charts.length - 1];
  const nextChart = charts[currentIndex + 1] || charts[0];

  return (
    <div className="chart-control">
      <button title={prevChart} onClick={() => {onChangeChart(prevChart); localStorage.setItem('chart', charts[currentIndex-1] || charts[charts.length - 1])}} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
        <FaChevronLeft />
      </button>
      
      <strong>{currentChart}</strong>
      
      <button title={nextChart}  onClick={() => {onChangeChart(nextChart); localStorage.setItem('chart', charts[currentIndex+1] || charts[0])}} style={{ cursor: 'pointer', border: 'none', background: 'none' }}>
        <FaChevronRight />
      </button>
    </div>
  );
}

export default ChartNavigator;