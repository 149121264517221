import React from 'react';

const TypeToggle = ({ type, setType }) => {
	return (
		<div className="toggle-switch-container">
		<span className="toggle-label">{type}</span>
			<label className="toggle-switch">
				<input
					type="checkbox"
					checked={type === 'Actual'}
					onChange={async(e) => {
						setType(e.target.checked ? 'Actual' : 'Planned');
					}}
				/>
				<span className="toggle-slider"></span>
			</label>
	</div>
	);
};
export default TypeToggle;