import React from 'react';
import { FaFilter, FaCloudDownloadAlt, FaPlus, FaPen, FaUpload, FaTrash  } from 'react-icons/fa';
import { CiFilter } from "react-icons/ci";
import axios from 'axios';

function ItemDataTableActions({ 
	user,
	itemType,
	isFiltersApplied,
	openFilterModal,
	openAddEditItemModal,
	openUploadModal,
	handleDeleteItems,
	handleDeleteItem,
	editingItemId,
	setLoading,
	showModal}) { 
  const api = axios.create({ baseURL: `${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_BACKEND_DEVELOPMENT : process.env.REACT_APP_BACKEND_PRODUCTION}/api` });
  const handleDownloadData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`items/export/${user.id}/${itemType}`, {
        responseType: "arraybuffer",
        headers:{
            Authorization: `Bearer ${token}`
        }
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "data_template_filled.xlsx"; // File name with .xlsx
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      showModal("Failed to download data, try again later");
    } finally {
      setLoading(false);
    }
  };
	return(
		<div className="data-actions-container">
			<div className="data-actions-left" title='Filter Data'>
				<button onClick={openAddEditItemModal} title={editingItemId ? "Edit Item" : "Add Item"}>
					{editingItemId ? <FaPen/> : <FaPlus/>}
				</button>
				<button onClick={openFilterModal}>
					{isFiltersApplied() ? <CiFilter /> : <FaFilter />}
				</button>
				{!editingItemId && <button onClick={handleDeleteItems} title="Delete Items">
					<FaTrash />
				</button>}
				{editingItemId && <button onClick={handleDeleteItem} title="Delete Item">
					<FaTrash />
				</button>}
			</div>
			<div className="data-actions-right">
				<button onClick={handleDownloadData} title='Download Data'>
					<FaCloudDownloadAlt />
				</button>
				<button onClick={openUploadModal} title='Upload Data'>
					<FaUpload />
				</button>
			</div>
		</div>
	);
}
export default ItemDataTableActions;