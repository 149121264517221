import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import profilePicture from '../images/default-profile.png';
import { FaPencilAlt, FaCheck, FaTimes, FaUpload } from 'react-icons/fa';
import DeleteProfileModal from '../Modals/DeleteProfileModal';
import Spinner from '../Modals/Spinner';
import { makeAuthenticatedRequest } from '../config/api';
import '../styles/Profile.css';

function Profile({ user, currencies, showModal, setUser, updateProfilePicture }) {
  const navigate = useNavigate();
  
  const [editing, setEditing] = useState(false);
  const [newUser, setNewUser] = useState(user);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [newPicture, setNewPicture] = useState(user.profilePictureUrl || profilePicture);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.profilePictureUrl) {
      fetchSignedUrl(user.profilePictureUrl);
    }
  }, [user]);

  const toggleEditing = () => {
    setEditing(!editing);
    setNewUser(user);
    setNewPicture(user.profilePictureUrl || profilePicture);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const isValidImage = selectedFile.type.startsWith('image/');
    const isFileSizeValid = selectedFile.size <= 5 * 1024 * 1024; // 5 MB limit
    const fileName = selectedFile.name;
    const isFileNameValid = fileName.length <= 255; // Assuming 255 is the database limit for file names
    if (!isValidImage) {
        showModal("Please select a valid image file.");
    } else if (!isFileSizeValid) {
        showModal("Please select a smaller file (less than 5MB).");
    } else if (!isFileNameValid) {
        showModal("File name is too long. Please use a shorter file name.");
    } else {
      // File is valid
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => setNewPicture(reader.result);
      reader.readAsDataURL(selectedFile);
    }
};

  const fetchSignedUrl = async () => {
    const getFileNameFromUrl = (url) => {
      const urlObj = new URL(url);
      const fileName = urlObj?.pathname.split('/').pop(); // Split by '/' and get the last part
      return fileName;
    };
    const isValidImageFile = (fileName) => {
      const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];
      const fileExtension = fileName ? fileName.slice(fileName.lastIndexOf('.')).toLowerCase() : '';
      return allowedExtensions.includes(fileExtension);
    };
    const fileName = getFileNameFromUrl(user.profilePictureUrl);
    if (fileName && isValidImageFile(fileName)) {
      const response = await makeAuthenticatedRequest(
        true,
        'get',
        `/users/${user.id}/profile-picture-url?fileName=${fileName}`
      );
      updateProfilePicture(response.data.signedUrl);
    }
  };
  
  const handleEditProfile = () => {
    if (!editing) return setEditing(true);
  
    setLoading(true);
  
    // Update user details
    const updates = {};
    if (newUser.name !== user.name) updates.name = newUser.name;
    if (newUser.birthdate !== user.birthdate) updates.birthdate = newUser.birthdate;
    if (newUser.gender !== user.gender) updates.gender = newUser.gender;
    if (newUser.currency !== user.currency) updates.currency = newUser.currency;
    const messages = [];

    if (Object.keys(updates).length > 0) {
      makeAuthenticatedRequest(true, 'put', `/users/${user.id}`, updates)
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          messages.push("update user details");
        });
    }
    if (file) {
      const formData = new FormData();
      formData.append('profilePicture', file);
      makeAuthenticatedRequest(true, 'post', `/users/${user.id}/profile-picture`, formData)
        .then((response) => {
          const fileUrl = response.data.fileUrl;
          setUser({ ...user, profilePictureUrl: fileUrl });
          updateProfilePicture(fileUrl);
          setFile(null);
        })
        .catch((error) => {
          messages.push("update profile picture");
        });
    }
    if (messages.length > 0) {
      showModal("Failed to " + messages.join(" and ") + " Please try again.")
    }
    setTimeout(() => {
      toggleEditing();
      setLoading(false);
    }, 2000);
  };
  

  if (!user || !user.id) return null; // Prevent rendering if user or user.id is missing

  return (
    <div className="profile-container">
                <div className="edit-profile-controls">
            {!editing ? (
              <span className="icon-edit" onClick={toggleEditing} title="Edit Profile">
                <FaPencilAlt />
              </span>
            ) : (
              <>
                <span className="icon-confirm" onClick={handleEditProfile} title="Confirm">
                  <FaCheck />
                </span>
                <span className="icon-cancel" onClick={toggleEditing} title="Cancel">
                  <FaTimes />
                </span>
              </>
            )}
          </div>
          <div className="profile-picture-section">
            <img
              src={editing ? newPicture : user.profilePictureUrl || profilePicture}
              alt="Profile"
              className="profile-picture"
              id="profile-picture-1"
              onError={(e) => (e.target.src = profilePicture)} // Fallback on error
            />
            {editing && (
              <div className="file-upload-section">
                <label htmlFor="profile-picture-upload" className="upload-label" style={{ cursor: 'pointer' }}>
                  <FaUpload /> Upload Picture
                </label>
                <input
                  type="file"
                  id="profile-picture-upload"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {file && (
                  <span
                    className="icon-cancel-picture"
                    onClick={() => {
                      setFile(null);
                      setNewPicture(user.profilePictureUrl || profilePicture);
                    }}
                    title="Cancel"
                  >
                    <FaTimes />
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="profile-info">
            <div className="name-container">
              <input
                type="email"
                value={user.email || ''}
                disabled={true}
              />
            </div>
            <div className="name-container">
              <input
                type="text"
                value={editing ? newUser.name : user.name || ''}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                autoFocus={editing}
                disabled={!editing}
              />
            </div>
            <div className="date-container">
              <input
                type="date"
                value={editing ? newUser.birthdate : user.birthdate || ''}
                onChange={(e) => setNewUser({ ...newUser, birthdate: e.target.value })}
                disabled={!editing}
              />
            </div>
            <div className="gender-container">
              <select
                value={editing ? newUser.gender : user.gender || ''}
                onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })}
                disabled={!editing}
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="currency-container">
              <select
                value={editing ? newUser.currency : user.currency || ''}
                onChange={(e) => setNewUser({ ...newUser, currency: e.target.value })}
                disabled={!editing}
              >
								<option value={''}>Please Select Currency</option>
								{currencies?.map((currency) => (
								<option key={currency.abbreviation} value={currency.abbreviation}>
									{currency.name} ({currency.abbreviation})
								</option>
                ))}
              </select>
            </div>
            <div className="modal-buttons">
              <button className="change-password-btn" onClick={() => navigate("/change-password")}>
                Change Password
              </button>
              <button className="delete-profile-btn" onClick={() => setDeleteModalOpen(true)}>
                Delete Profile
              </button>
            </div>
            {deleteModalOpen && (
              <DeleteProfileModal
                user={user}
                setUser={setUser}
                showModal={showModal}
                setDeleteModalOpen={setDeleteModalOpen}
              />
            )}
          </div>
      {loading && <Spinner message="Updating Profile ..." />}
    </div>
  );
}

export default Profile;
