import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import {balancesCache, treasuriesCache, allTreasuriesCache} from '../services/cache';

const BalanceModal = ({
  onClose,
  currency,
  value,
  user}) => {
  const [treasuries, setTreasuries] = useState([]);
  const [treasuryId, setTreasuryId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTreasuries(currency);
  }, []);

  const fetchTreasuries = async(currency) => {
    const cacheKey = treasuriesCache.getKey(user.id, true, 0, currency);
    const cachedData = treasuriesCache.get(cacheKey);
    if (cachedData) {
      setTreasuries(cachedData.treasuries);
      setLoading(false);
      return;
    }
    try {
      const response = await makeAuthenticatedRequest(true, 'get', `/treasuries/user/${user.id}?currency=${currency}`);
      if (Array.isArray(response.data)) {
        const sortedT = response.data.sort((a, b) => {a.name.localeCompare(b.name || '')});
        treasuriesCache.set(cacheKey, { treasuries: sortedT });
        setTreasuries(sortedT);
      }
    } catch (error) {
      setError("Couldn't get Treasuries");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateBalance = async () => {
    try {
      setLoading(true);
      const treasury = treasuries.find(t => t.id == treasuryId);
      const currentBalance = (Number(treasury.lastBalance) || 0) + Number(value || 0);
      await makeAuthenticatedRequest(true, 'put', `/treasuries/balance/${treasury.id}`, { lastBalance: currentBalance, date: new Date() });
      balancesCache.clear();
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchTreasuries(currency);       
      onClose();
    } catch (error) {
      setError("Failed to update balance");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Add Balance</h3>
        <p style={{color: 'red'}}>{error}</p>
        <select style={{width: '100%', padding: '10px', marginBottom: '15px', 
                      fontSize: '1em', borderRadius: '4px', border: '1px solid #ddd'}}
          value={treasuryId}
          onChange={(e) => setTreasuryId(e.target.value)}
        >
          <option value={''}>Please Select Treasury</option>
          {treasuries.map((treasury) => (
            <option key={treasury.id} value={treasury.id}>{treasury.name}</option>
          ))}
        </select>
        <div className="modal-buttons">
          <button className="confirm-btn" disabled={loading} onClick={handleUpdateBalance}>Add Balance</button>
          <button className="cancel-btn" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default BalanceModal;