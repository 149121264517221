import React, { useRef, useState } from 'react';
import axios from 'axios';
import {itemsCache, allCategoriesCache, allSubcategoriesCache, categoriesCache, subcategoriesCache} from '../services/cache';

const ItemsBulkUploadModal = ({
  user,
  onClose,
  fetchItems,
  fetchCategoriesAndSubcategories
}) => {
  const [loading, setLoading] = useState(false); // New loading state
  const [error, setError] = useState('');
  const api = axios.create({ 
    baseURL: `${process.env.REACT_APP_ENV === "development" 
      ? process.env.REACT_APP_BACKEND_DEVELOPMENT 
      : process.env.REACT_APP_BACKEND_PRODUCTION}/api` 
  });
  const fileInputRef = useRef(null);

  const handleDownloadTemplate = async () => {
    try {
      const response = await api.get(`data/items/template`, {
        responseType: "arraybuffer", // Ensure binary data is received
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "template.xlsx"; // File name with .xlsx
      link.click();
  
      URL.revokeObjectURL(url);

      onClose();
    } catch (error) {
      setError("Failed to download template");
    }
  };

  const handleFileChange = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const token = localStorage.getItem('token');
    try {
        await api.post(`/data/items/upload/${user.id}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        itemsCache.clear();
        allCategoriesCache.clear();
        allSubcategoriesCache.clear();
        categoriesCache.clear();
        subcategoriesCache.clear();
        await fetchItems();
        await fetchCategoriesAndSubcategories();
    } catch (error) {
        setError("Failed to upload data");
    } finally {
        setLoading(false);
    }
};


  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    const isExcelFile = selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || selectedFile.type === 'application/vnd.ms-excel';
    const isFileSizeValid = selectedFile.size <= 10 * 1024 * 1024; // 10 MB limit
    const fileName = selectedFile.name;
    const isFileNameValid = fileName.length <= 255; // Assuming 255 is the database limit for file names
    if (!isExcelFile) {
        setError("Please select a valid Excel file (e.g., .xlsx or .xls).");
        return;
    } else if (!isFileSizeValid) {
        setError("Please select a smaller file (less than 10MB).");
        return;
    } else if (!isFileNameValid) {
        setError("File name is too long. Please use a shorter file name.");
        return;
    }
    try {
      await handleFileChange(selectedFile);
      onClose();
    } catch (error) {
      setError('Upload failed');
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h3>Bulk Data Upload</h3>
        <p>Download the empty template excel file, fill it then re-upload it.</p>
        <p style={{color: 'red'}}>{error}</p>
        <div className="modal-buttons">
            <button 
              onClick={handleDownloadTemplate}
              className="confirm-btn"
            >
              Get Template
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <button 
              onClick={triggerFileInput}
              className="confirm-btn"
              disabled={loading}
            >
              {loading ? 'Uploading...' : 'Upload Data File'}
            </button>
            <button onClick={onClose} className="cancel-btn">
              Close
            </button>
        </div>
      </div>
    </div>
  );
};

export default ItemsBulkUploadModal;