import { makeAuthenticatedRequest } from '../config/api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function DeleteProfileModal({user, setUser, showModal, setDeleteModalOpen}) {
  const [deletePassword, setDeletePassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleDeleteProfile = async () => {
    setIsLoading(true);
  
    if (!deletePassword) {
      setIsLoading(false);
      showModal("Please write your Login password");
      return;
    }
  
    makeAuthenticatedRequest(true, 'delete', `/users/${user.id}`, { password: deletePassword })
    .then(response => {
      setTimeout(() => {
        localStorage.clear(); // Clear invalid tokens
        setUser(null);
        navigate("/"); // Redirect to home
        setDeleteModalOpen(false); // Close the modal only on success
        showModal("Profile deleted successfully.");
      }, 2000);
    }).catch(error => {
      setDeleteModalOpen(false); // Close the modal only on success
      showModal("Couldn't delete profile: ", error);
      setIsLoading(false);
    });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Confirm Profile Deletion</h3>
        <p>To confirm, please enter your password:</p>
        <p style={{color:'red', fontWeight: '0.7em'}}><strong>This action cannot be undone</strong></p>
        {isLoading ? <p>Deleting profile...</p>: 
        <input
          className="delete-password-input"
          type="password"
          placeholder="Password"
          value={deletePassword}
          onChange={(e) => setDeletePassword(e.target.value)}
        />}
        <div className="modal-buttons">
          <button onClick={handleDeleteProfile} className="confirm-delete-btn" disabled={isLoading}>
            Confirm
          </button>
          <button onClick={() => setDeleteModalOpen(false)} className="cancel-btn">Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteProfileModal;