import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../config/api';
import Spinner from '../Modals/Spinner';
import '../styles/VerifyAccount.css';

function VerifyAccount({ user, showModal }) {
  const [loading, setLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(false); // Stop loading when user is available
    }
  }, [user]);

  const handleResend = async () => {
    setResendLoading(true); // Start loading for resend
    makeAuthenticatedRequest(true, 'post', '/users/verify-account', {
      email: user.email,
    }).then(response => {
      setTimeout(() => {
        showModal("Verification email sent successfully");
      }, 2000);
    }).catch(error => {
      showModal("Couldn't send email, please try again later");
    }).finally(() => setResendLoading(false))
  };

  if (loading) {
    return <Spinner message="Verifying your token..."/>;
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="verify-account-container">
      <h1>Welcome, {user.name || user.email}!</h1>
      <p>
        Please check your email to verify your account. If you can't find the email, check your junk or spam folder.
      </p>
      <button
        className="resend-email-btn"
        onClick={handleResend}
        disabled={resendLoading}
      >
        Resend Link
      </button>
      {resendLoading && <Spinner message="Sending verification email" />}
    </div>
  );
}

export default VerifyAccount;
