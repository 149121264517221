import '../styles/Footer.css';

function Footer() {
    return (
      <footer>
        <p>My Finance App © 2024. All rights reserved.</p>
      </footer>
    );
  }
  
  export default Footer;
  