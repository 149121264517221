import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { format, addMonths, parse } from 'date-fns';

function LineChart({ allTreasuries, items }) {
  if (!allTreasuries || allTreasuries.length === 0) {
    return <p>No data to analyze. Add some treasuries and try again.</p>;
  }

  // Extract unique month-year combinations from transactions
  const uniqueDates = new Set();
  items.forEach(item => uniqueDates.add(format(new Date(item.date), 'yyyy-MM')));
  const earliestDate = Array.from(uniqueDates).sort()[0]; // Get the earliest date
  const currentMonth = format(new Date(), 'yyyy-MM'); // Get the current month
  const allMonths = [];
  let current = parse(earliestDate, 'yyyy-MM', new Date());
  const end = parse(currentMonth, 'yyyy-MM', new Date());
  while (current <= end) {
    allMonths.push(format(current, 'yyyy-MM'));
    current = addMonths(current, 1);
  }
  const monthLabels = allMonths.map(date => format(parse(date, 'yyyy-MM', new Date()), 'MMM yyyy'));


  // Prepare datasets for the chart
  const datasets = allTreasuries.map(treasury => {
    let lastKnownBalance = null; // Initialize lastKnownBalance for this treasury

    const monthlyBalances = monthLabels.map(monthLabel => {
      const monthKey = format(new Date(monthLabel), 'yyyy-MM');

      // Get all balances for this month
      const monthBalances = treasury.Balances
        .filter(balance => format(new Date(balance.date), 'yyyy-MM') === monthKey)
        .map(balance => ({
          ...balance,
          balance: treasury.type === 'credit'
            ? Number(balance.balance) - Number(balance.limit)
            : Number(balance.balance),
        }));

      // Use the latest balance for the month or fallback to the last known balance
      if (monthBalances.length > 0) {
        lastKnownBalance = Number(monthBalances[0].balance);
        return lastKnownBalance;
      }

      // If no balance exists for this month, use the last known balance
      return lastKnownBalance;
    });

    // Build the dataset for the treasury
    return {
      label: `${treasury.name} (${treasury.type === 'credit' ? 'Credit Card' : 'Account'})`,
      data: monthlyBalances,
      borderColor: treasury.type === 'credit' ? 'rgba(255, 99, 132, 1)' : 'rgba(75, 192, 192, 1)',
      backgroundColor: treasury.type === 'credit' ? 'rgba(255, 99, 132, 0.2)' : 'rgba(75, 192, 192, 0.2)',
      fill: true,
      tension: 0.4,
      pointStyle: treasury.type === 'credit' ? 'rectRot' : 'circle',
      pointRadius: 4,
    };
  });

  const treasuryStartDates = new Map();
  allTreasuries.forEach(treasury => {
    const firstBalance = treasury.Balances[0];
    if (firstBalance) treasuryStartDates.set(treasury.id, firstBalance.date);
  });

  const monthlyData = {};
  const expectedBalances = {};
  let runningExpectedBalance = 0;

  monthLabels.forEach((month, index) => {
    const Balances = [];
    allTreasuries.forEach(treasury => {
      const Balance = treasury.Balances.find(b => format(new Date(b.date), 'MMM yyyy') === month);
      if (Balance) {
        Balances.push({ ...Balance, type: treasury.type });
      }
    });
    const actualBalance = Balances.reduce((sum, balance) => {
      const currentBalance = Number(balance.balance);
      const limit = Number(balance.limit || 0); // Default to 0 if limit is undefined
      return sum + (balance.type === 'debit' ? currentBalance : currentBalance - limit);
    }, 0);
    if (index == 0) {
      runningExpectedBalance += actualBalance;
    } else {
      const netFlow = items
      .filter(item => format(new Date(item.date), 'MMM yyyy') === month)
      .reduce((sum, item) => sum + (Number(item.credit) || 0) - (Number(item.debit) || 0), 0);
      runningExpectedBalance += netFlow;
    }
    expectedBalances[month] = runningExpectedBalance;
    monthlyData[month] = actualBalance;
  });
  // Create a dataset for the Expected Balance
  datasets.push({
    label: 'Expected Balance',
    data: monthLabels.map(month => expectedBalances[month]),
    borderColor: 'rgba(153, 102, 255, 1)',
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    borderDash: [5, 5],
    fill: false,
  });
  // Add "Actual Balance" dataset
  datasets.push({
    label: 'Actual Balance',
    data: monthLabels.map(month => monthlyData[month]),
    borderColor: 'rgba(75, 192, 192, 1)',
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderDash: [5, 5],
    fill: false,
  });

  // Analytics Function
  const getChartAnalytics = (allTreasuries, items) => {
    const totalCredit = allTreasuries
      .filter(t => t.type === 'credit')
      .reduce((sum, t) => sum + Number(t.limit), 0);

    const totalDebitBalance = allTreasuries
      .filter(t => t.type === 'debit')
      .reduce((sum, t) => {
        const latestBalance = t.Balances.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
        return sum + (latestBalance ? Number(latestBalance.balance) : 0);
      }, 0);

      const totalCreditBalance = allTreasuries
      .filter(t => t.type === 'credit')
      .reduce((sum, t) => {
        const latestBalance = t.Balances.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
        return sum + (latestBalance ? Number(latestBalance.balance) : 0);
      }, 0);

    const creditUtilization = totalCredit === 0 ? 0 : (1-(totalCreditBalance) / totalCredit) * 100;

    const monthlyChange = {};
    items.forEach(item => {
      const month = format(new Date(item.date), 'MMM yyyy');
      if (!monthlyChange[month]) monthlyChange[month] = 0;
      monthlyChange[month] += (Number(item.credit) || 0) - (Number(item.debit) || 0);
    });

    const lastMonthNetChange = Object.values(monthlyChange).pop() || 0;

    return {
      summary: {
        totalAssets: (totalDebitBalance + totalCreditBalance).toFixed(2),
        availableCredit: (totalCreditBalance).toFixed(2),
        creditUtilization: creditUtilization.toFixed(1) + '%',
        lastMonthChange: lastMonthNetChange.toFixed(2),
      },
      alerts: [
        creditUtilization > 30 ? `Warning: Credit utilization at ${creditUtilization.toFixed(1)}%` : null,
        lastMonthNetChange < 0 ? `Alert: Negative cash flow last month: ${lastMonthNetChange.toFixed(2)}` : null,
        totalCreditBalance < totalCredit * 0.1 ? `Low balance warning: Total balance below 10% of credit limit` : null,
      ].filter(alert => alert),
    };
  };

  const analytics = getChartAnalytics(allTreasuries, items);

  return (
    <div className="chart">
      <h3>Balance History and Cash Flow</h3>
      <div
        style={{
          marginBottom: '20px',
          padding: '10px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div>Total Assets: {analytics.summary.totalAssets}</div>
          <div>Available Credit: {analytics.summary.availableCredit}</div>
          <div>Credit Utilization: {analytics.summary.creditUtilization}</div>
          <div>Last Month Change: {analytics.summary.lastMonthChange}</div>
        </div>
        {analytics.alerts.length > 0 && (
          <div style={{ color: '#d32f2f' }}>
            {analytics.alerts.map((alert, i) => (
              <div key={i}>⚠️ {alert}</div>
            ))}
          </div>
        )}
      </div>
      <Line
        data={{
          labels: monthLabels,
          datasets,
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: { title: { display: true, text: 'Date' } },
            y: { title: { display: true, text: 'Amount' }, stacked: false },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export default LineChart;