import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import Spinner from '../Modals/Spinner';

function VerifyToken({ setUser, showModal }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');
    const type = query.get('type');

    if (token && email) {
      makeAuthenticatedRequest(false, 'post', '/users/verify-token', { token, email })
        .then(response => {
          const data = response.data;
          if (data) {
            localStorage.setItem("id", data.userId);
            localStorage.setItem("token", token);
            setVerified(true);
            setMessage('Token verified! Redirecting...');
            setTimeout(() => {
              setLoading(false);
              if (type === 'account') {
                localStorage.removeItem("token");
                localStorage.removeItem("id");
                setUser(null);
                showModal("Account Verified Successfully please Login");
                navigate('/');
              } else {
                setUser(null);
                navigate('/reset-password');
              }
            }, 2000);
          }
        })
        .catch(error => {
          showModal("Token verification failed ", error);
          setVerified(false);
          setMessage('Token verification failed! Redirecting...');
          setLoading(false);
          navigate('/');
        });
    } else {
      navigate('/');
    }
  }, [location.search, navigate, setUser, showModal]);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {loading ? (
        <>
          <Spinner message="Verifying your token..."/>
        </>
      ) : verified ? (
        <p style={{ color: 'green' }}>{message}</p>
      ) : (
        <p style={{ color: 'red' }}>{message}</p>
      )}
    </div>
  );
}

export default VerifyToken;