import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarController,
  ArcElement
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarController,
  ArcElement
);

function BarChart({ items }) {
  const [chartType, setChartType] = useState('spending'); // spending, income, ratio
  
  if (!items || items.length === 0) {
    return <p>No data to analyze. Add some transactions and try again.</p>;
  }

  // Process data by categories
  const processData = () => {
    const categoryTotals = items.reduce((acc, item) => {
      const monthKey = format(new Date(item.date), 'MMM yyyy');
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          categories: {},
          totalDebit: 0,
          totalCredit: 0
        };
      }
      
      const category = item.category || 'Uncategorized';
      if (!acc[monthKey].categories[category]) {
        acc[monthKey].categories[category] = {
          debit: 0,
          credit: 0
        };
      }
      
      acc[monthKey].categories[category].debit += Number(item.debit) || 0;
      acc[monthKey].categories[category].credit += Number(item.credit) || 0;
      acc[monthKey].totalDebit += Number(item.debit) || 0;
      acc[monthKey].totalCredit += Number(item.credit) || 0;
      
      return acc;
    }, {});

    return categoryTotals;
  };

  const categoryData = processData();
  const months = Object.keys(categoryData).sort((a, b) => new Date(a) - new Date(b));
  const allCategories = [...new Set(
    months.flatMap(month => Object.keys(categoryData[month].categories))
  )].sort();

  // Prepare chart data based on chart type
  const getChartData = () => {
    switch(chartType) {
      case 'spending':
        return {
          labels: months,
          datasets: allCategories.map((category, index) => ({
            label: category,
            data: months.map(month => 
              categoryData[month].categories[category]?.debit || 0
            ),
            backgroundColor: `hsl(${(index * 360) / allCategories.length}, 70%, 60%)`,
            stack: 'stack'
          }))
        };

      case 'income':
        return {
          labels: months,
          datasets: allCategories.map((category, index) => ({
            label: category,
            data: months.map(month => 
              categoryData[month].categories[category]?.credit || 0
            ),
            backgroundColor: `hsl(${(index * 360) / allCategories.length}, 70%, 60%)`,
            stack: 'stack'
          }))
        };

      case 'ratio':
        return {
          labels: months,
          datasets: [{
            label: 'Income/Expense Ratio',
            data: months.map(month => {
              const totalDebit = categoryData[month].totalDebit;
              const totalCredit = categoryData[month].totalCredit;
              return totalDebit === 0 ? 0 : (totalCredit / totalDebit);
            }),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            barThickness: 30
          }]
        };

      default:
        return null;
    }
  };

  // Chart options based on type
  const getChartOptions = () => {
    const baseOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: chartType !== 'ratio',
          title: { display: true, text: 'Month' }
        },
        y: {
          stacked: chartType !== 'ratio',
          title: { 
            display: true, 
            text: chartType === 'ratio' ? 'Income/Expense Ratio' : 'Amount'
          },
          beginAtZero: true
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              if (chartType === 'ratio') {
                return `Ratio: ${context.raw.toFixed(2)}`;
              }
              return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
            }
          }
        }
      }
    };

    if (chartType === 'ratio') {
      baseOptions.plugins.annotation = {
        annotations: {
          line1: {
            type: 'line',
            yMin: 1,
            yMax: 1,
            borderColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5],
            label: {
              content: 'Break Even',
              enabled: true
            }
          }
        }
      };
    }

    return baseOptions;
  };

  return (
    <div className="chart">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h3>Category Analysis</h3>
        <div>
          <select 
            value={chartType}
            onChange={(e) => setChartType(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ddd'
            }}
          >
            <option value="spending">Spending by Category</option>
            <option value="income">Income by Category</option>
            <option value="ratio">Income/Expense Ratio</option>
          </select>
        </div>
      </div>

      <Bar 
        data={getChartData()}
        options={getChartOptions()}
      />

      <div style={{ marginTop: '20px' }}>
        {chartType !== 'ratio' && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              Total Categories: {allCategories.length}
            </div>
            <div>
              Highest Category: {
                allCategories.reduce((highest, category) => {
                  const total = months.reduce((sum, month) => 
                    sum + (chartType === 'spending' ? 
                      (categoryData[month].categories[category]?.debit || 0) :
                      (categoryData[month].categories[category]?.credit || 0)), 
                  0);
                  return total > highest.total ? { category, total } : highest;
                }, { category: '', total: 0 }).category
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BarChart;