import React, { useState, useEffect } from 'react';
import { makeAuthenticatedRequest } from '../config/api';
import axios from 'axios';
import {balancesCache, treasuriesCache, allTreasuriesCache} from '../services/cache';
import { FaCheck, FaPencilAlt, FaTimes, FaTrash } from 'react-icons/fa';

function Balance({id, onClose, fetchTreasuries}) {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [treasury, setTreasury] = useState(null);
  const [error, setError] = useState('');
  const [newBalance, setNewBalance] = useState({date: '', balance: 0});
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    fetchBalances();
  }, []);

  const api = axios.create({ baseURL: `${process.env.REACT_APP_ENV === "development" ? process.env.REACT_APP_BACKEND_DEVELOPMENT : process.env.REACT_APP_BACKEND_PRODUCTION}/api` });

  const fetchBalances = async() => {
    const cacheKey = balancesCache.getKey(id);
    const cachedData = balancesCache.get(cacheKey);
    if (cachedData) {
      setTreasury(cachedData.treasury);
      setBalances(cachedData.treasury.Balances);
      setLoading(false);
      return;
    }
    try {
			const treasuryResponse = await makeAuthenticatedRequest(
				true,
				'get',
				`/treasuries/balances/${id}`
			);
			const treasury = treasuryResponse.data;
      balancesCache.set(cacheKey, { treasury: treasury });
			setTreasury(treasuryResponse.data);
			if (Array.isArray(treasury.Balances)) {
				setBalances(treasury.Balances);
			}
    } catch (error) {
      setError("Couldn't Get History");
    } finally {
			setLoading(false);
		}
  }

  const DeleteBalance = async (id) => {
    setLoading(true);
    try {
      await makeAuthenticatedRequest(true, 'delete', `/balances/${id}`);
      balancesCache.clear();
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchBalances();
      await fetchTreasuries();
      setError('');
    } catch (error) {
      setError("Failed to delete item");
    } finally {
      setLoading(false);
    }
  }

  const handleAddOrEdit = async () => {
    setLoading(true);
    try {
      if (newBalance.id && newBalance.id != 0) {
        await makeAuthenticatedRequest(true, 'put', `/balances/${newBalance.id}`, newBalance);
      } else {
        const addedBalance = {balance: newBalance.balance, date: newBalance.date, treasuryId: id};
        await makeAuthenticatedRequest(true, 'post', `/balances`, addedBalance);
      }
      balancesCache.clear();
      treasuriesCache.clear();
      allTreasuriesCache.clear();
      await fetchBalances();
      await fetchTreasuries();
      setError('');
    } catch (error) {
      setError("Failed to edit item");
    } finally {
      setNewBalance({id:'', date:'', balance:0})
      setEditing(false);
      setLoading(false);
    }
  };

  const handleAdd = () => {
    const newBalances = [{id:0, balance:0, date: new Date().toISOString()}].concat(balances);
    setNewBalance({...newBalance, date: new Date().toISOString(), id: 0})
    setBalances(newBalances);
    setEditing(true);
  }

  const handleDownload = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`balances/export/${id}`, {
        responseType: "arraybuffer",
        headers:{
            Authorization: `Bearer ${token}`
        }
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = treasury.name + "_balances_history.xlsx"; // File name with .xlsx
      link.click();
      URL.revokeObjectURL(url);
      setError('');
    } catch (error) {
      setError("Failed to download data, try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {balances.length === 0 ? 
          loading ? <p>Loading...</p> : <p>No balances recorded yet.</p>
           : (
          <>
            <h3>{treasury.name}'s History</h3>
            <p style={{color: 'red'}}>{error}</p>
            <div className="data-table-scroll" style={{overflowY: 'scroll', overflowX: 'unset', maxHeight: '166px'}}>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {balances
                  .map((balance) => (
                    <tr key={balance.id}>
                      <td>
                        {newBalance.id == balance.id && editing ? 
                        <>
                          <FaTimes size={15} title="Cancel Edit" style={{color: 'red', cursor: 'pointer'}} onClick={() => {setNewBalance({id:'', date:'', balance:0}); fetchBalances(); setEditing(false);}}/>
                          <FaCheck size={15} title="Finish Edit" style={{color: 'green', cursor: 'pointer'}} onClick={async() => {await handleAddOrEdit()}}/>
                        </> : 
                        <>
                          <FaTrash size={15} title="Delete Balance" style={{color: 'red', cursor: 'pointer'}} onClick={async() => {if(!loading) {await DeleteBalance(balance.id)}}}/>
                          <FaPencilAlt size={15} title="Edit Balance" style={{color: 'blue', cursor: 'pointer'}} onClick={() => {setNewBalance(balance); setEditing(true);}}/>
                        </>}
                      </td>
                      <td>
                        <input 
                          style={{width: '100%', height: '100%', fontSize: '15px'}}
                          type="date"
                          disabled={!editing || newBalance.id != balance.id} 
                          value={new Date(newBalance.id == balance.id ? newBalance.date : (balance.date || '')).toISOString().split('T')[0]}
                          onChange={async(e) => setNewBalance({...newBalance, date: e.target.value})}
                        />
                      </td>
                      <td>
                        <input 
                          style={{width: '100%', height: '100%', fontSize: '15px'}}
                          type="number"
                          disabled={!editing || newBalance.id != balance.id} 
                          value={newBalance.id == balance.id ? newBalance.balance : (balance.balance || 0)}
                          onChange={async(e) => setNewBalance({...newBalance, balance: e.target.value})}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>)}
          <div className="modal-buttons">
            <button className="cancel-btn" onClick={onClose}>Close</button>
            <button className="confirm-btn" disabled={loading} onClick={() => handleAdd()}>Add</button>        
						{balances.length > 0 && <button className="confirm-btn" disabled={loading} onClick={() => handleDownload()}>Download</button>}          
				</div>
      </div>
    </div>
  );
}

export default Balance;